.search-main-ctn {
  display: flex;
  margin-top: 60px;
  flex-wrap: wrap;
  position: relative;

  .search-field {
    width: 87%;
    padding-right: 35px;
    position: relative;

    input {
      font-size: 24px;
      color: #000;
      width: 100%;
      background: transparent;
      border: none;
      border-bottom: 1px solid #d4d4d4;
      border-radius: 0;
      padding: 0;
      position: relative;
      vertical-align: middle;
      z-index: 1;
      height: 36px;
      outline: none;
      box-shadow: none;
      border-bottom: 1px solid #d4d4d4;
      font-weight: 100;
    }
  }

  .link-arrow {
    color: #e40000;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    font-size: 20px;
    line-height: 24px;
    background: none;
    border: none;
    text-transform: uppercase;
    font-weight: 300;
    padding: 11px 0;

    &:after {
      font-family: airtel-icons;
      content: "\F16E";
      font-size: 130%;
      vertical-align: sub;
      margin-left: 15px;
      display: inline-block;
    }
  }

  .suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 100%;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 16px;
    text-align: left;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-radius: 0;
    box-shadow: 3px 3px 2px rgba(0, 0, 0, .09);
    overflow: auto;
    max-height: 300px;

    .suggestion {
      padding: 8px 20px;
      font-weight: 100;
      font-size: 14px;
      color: #000;
      text-decoration: none;
      outline: 0;
      background-color: #ffffff;
      cursor: pointer;
    }

    .suggestion strong {
      color: #e40000;
    }

    .suggestion:hover {
      background-color: #e40000;
      color: #ffffff;
    }

    .suggestion:hover strong {
      color: #fff;
    }
  }
}