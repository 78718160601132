
.back-btn
{
    background: none;
    border: none;
    color: #e40000;
    font-size: 15px;
    position: relative;
    top: -20px;
    i {
        margin: 0 12px 0 0;
        font-size: 19px;
        position: relative;
        top: 4px;
        display: inline-block;

    }
    &:hover {
        i {
            animation: linkanimation 0.7s linear;
        }
    }
}

.we-are-wrap {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;

    h2 {
        font-size: 80px;
        line-height: 80px;
        letter-spacing: -.8px;
        font-weight: 100;
    }
}

.help-wrapper {
    .row {
        margin: 0;
    }

    .categories {
        height: 160px;
        display: flex;
        align-items: center;
        vertical-align: middle;
        font-size: 24px;
        font-weight: 100;
        background-color: #e40000;
        color: #fff;
        cursor: pointer;

        span {
            padding-left: 30px;
        }

        &::before {
            font-size: 40px;
            margin-top: -8px;
            font-family: airtel-icons;
        }

        &:nth-child(1) {
            &:before {
                content: "\F19E";
            }
        }

        &:nth-child(2) {
            background: #8d0707;

            &:before {
                content: "\F185"
            }
        }

        &:nth-child(3) {
            background: #a30000;

            &:before {
                content: "\F13C"
            }
        }

        &:nth-child(4) {
            background: #b50a02;

            &:before {
                content: "\F185";
                font-family: airtel-icons;
               
            }
        }

        &:nth-child(5) {
            background: #6b050b;

            &:before {
                content: "\F13C";
            }
        }

        &:nth-child(6) {
            background: #b50a02;

            &:before {
                content: "\F19E";
            }
        }
    }

    .faq-wrap {
        display: flex;
       flex-wrap: wrap;
       padding-top: 70px;

        h3 {
            color: #707070;
        
            padding-right: 30px;
            font-size: 48px;
            line-height: 50px;
            letter-spacing: -.4px;
            font-weight: 100;
        }
        .questions {
            display: block;
            padding-bottom: 30px;
            color: #000;
            font-size: 20px;
            line-height: 24px;
            font-weight: 100;
            cursor: pointer;
        }
    }
}
.need-help {
    padding: 50px 0;
    background: #555;
    margin-top: 40px;
    h3 {
        font-size: 36px;
        line-height: 40px;
        letter-spacing: -.1px;
        color: #fff;
        font-weight: 100;
    }
    .item-iconWwrap {
        display: flex;
        .item-icon {
            text-align: center;
            width: 20%;
            cursor: pointer;
    
        i {
            color: #fff;
            font-size: 50px;
            display: block;
            margin-bottom: 0;
        }
        span {
            color: #fff;
            font-size: 13px;
        }
    }
    }
}
@media (max-width:992px) {
   
    .we-are-wrap h2 {
        font-size: 33px;
        line-height: normal;
    }
    .search-main-ctn {
        margin-top: 0px;
    }
    .help-wrapper .categories {
    span {
        font-size: 18px;
    }
        width: 50%;
        &:last-child {
            width: 100%;
        }
    }
    .help-wrapper .faq-wrap h3,
    .card-questions-container h3 {
        font-size: 32px;
        margin-bottom: 20px;
    }
    .help-wrapper .faq-wrap .questions,
    .card-questions-container .questions {
        font-size: 17px;
    }
    .top-wrapper h3 {
        font-size: 25px;
        line-height: normal;
    }
    .need-help .item-iconWwrap .item-icon {
        text-align: left;
        width: 40%;
        cursor: pointer;
    }
    .need-help .item-iconWwrap {
        display: flex;
        width: 100%;
    }    
    .category-wrapper .content-title {
        font-size: 33px;
        line-height: normal;
        margin-bottom: 20px;
    }
    .category-wrapper .category {
        width: 50%;
    }
    section.banner article {
        min-height: 209px;
       
    }
    
}