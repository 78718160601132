#map{
    height:100%;
    width:100vw;
    min-height: 300px;
   
}
.location-wrapper {
    #map{ 
        height:460px;
        width:100%;
    }
    position: relative;
    padding: 0 60px;
    margin: 20px 0 0;

    .location {
        position: absolute;
        right: 120px;
        z-index: 11;
        top: 20px;
        display: flex;
        align-items: center;
        input {
            transition: all .2s ease-in-out;
            font-size: 16px;
            color: #181818;
            width: 100%;
            background: #fff !important;
            border: 1px solid #5f5f5f;
            border-radius: 0;
            box-shadow: inset 0 0 0 2px transparent;
            display: inline-block;
            padding: 2px 20px;
            position: relative;
            vertical-align: middle;
            z-index: 1;
            line-height: 40px;
            height: 45px;
            padding-right: 38px;
            width: 300px;
           
        }
        a {
            font-weight: 500;
            background: #e40000;
            color: #ffffff;
            border: none;
            cursor: pointer;
            font-size: 20px;
            height: 45px;
            width: 44px;
            line-height: 44px;
            min-width: 44px;
            padding: 0;
            display: inline-block;
            text-align: center;
            margin-left: 0px;
            position: relative;
        }
    }
}

.radio-container{
    display:flex;
   margin: 40px 0 0;

 
}
.radios label {
    margin: 0;
    position: relative;
    vertical-align: middle;
    font-weight: 100;
    font-size: 16px;
    line-height: 16px;
    margin-right: 25px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

 .radios input {
    position: absolute;
    top: 0;
    left: -99999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 28px;
    width: 28px;
    border: 0;
    padding: 0;
    margin: 4px 0 0;
    line-height: normal;
}
.radios .radio {
    transition: box-shadow .2s ease-in-out;
    height: 28px;
    width: 28px;
    background: #fff;
    border: 1px solid #707070;
    box-shadow: inset 0 0 0 2px transparent;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px;
    border-radius: 100%;
}
.radios input~span {
    color: #707070;
}
.radios input:checked+.radio:after {
    position: absolute;
    top: 9px;
    left: 9px;
    height: 8px;
    width: 8px;
    background: #e40000;
    border-radius: 8px;
    content: "";
}

 .radios input:checked~span {
    color: #000;
}

@media (max-width:992px) {
   
    .location-wrapper {
        padding: 0;

        .location {
            position: static;
            margin: 90px auto 50px;
            max-width: 230px;
            justify-content: center;
        }
    }
}