.content-wrap {
  .padding-bottom-10,
   .pb-10 {
    padding-bottom: 10px !important;
}
#midContainer .midInCon ul li p {
    margin: 0;
}
  .main-header {
    background-position-y: top;
    padding-top: 0 !important;
    &:after,
    &:before {
      display: none;
    }
  }
  .m-fs-55 {
    font-size: 55px;
}
.m-lh-65 {
  line-height: 65px;
}
.m-fs-40 {
  font-size: 40px;
}
.m-lh-45 {
  line-height: 45px;
}
  article#dealers>.row {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .termAndConditions {
    width: auto;
    margin-left: auto;
    border: none;
    border-radius: 5px;
    color: #666;
    background: #fff;
  }

  .termAndConditions article {
    border: 1px solid #666;
    padding: 30px;
  }

  .termAndConditions p {
    font-size: 12px;
    text-align: justify;
    line-height: 1.3;
    margin-bottom: 12px;
    color: #000 !important;
    font-weight: 400;
  }

  .pageHeadingContent p {
    margin-bottom: 10px;
    color: #000 !important;
  }

  p {
    line-height: normal;
  }

  .h5,
  h5 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
  }

  .font-weight-500 {
    font-weight: 400 !important;
  }

  section {
    padding-top: 60px;
    padding-bottom: 60px;
   
  }
 section:before, 
 section:after {
    content: "";
    display: table;
    width: 100%;
}

  .padding-top-0,
  .pt-0 {
    padding-top: 0 !important;
  }

  .bg-white {
    background: #fff !important;
  }

  // [class*="icon-"],
  // [class^=icon-] {
  //   font-family: airtel-icons;
  //   display: inline-block;
  //   vertical-align: middle;
  //   line-height: 1;
  //   font-weight: 400;
  //   font-style: normal;
  //   speak: none;
  //   text-decoration: inherit;
  //   text-transform: none;
  //   text-rendering: optimizeLegibility;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  // }

  .icon-outlined-link-arrow-right:before,
  .icon-outlined-link-right-arrow:before {
    content: "\F16E";
  }

  .link .icon-outlined-link-right-arrow {
    font-size: 130%;
    vertical-align: text-bottom;
    margin-left: 8px;
  }




  .font-weight-400 {
    font-weight: 400;
  }

  .font-size-20,
  .fs-20 {
    font-size: 20px;
    line-height: 24px;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: 100;
    line-height: 1.1;
    color: inherit;
  }

  .h4,
  h4 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -.1px;
  }

  .padding-left-15,
  .pl-15 {
    padding-left: 15px !important;
  }

  section.bg-white {
    h1.line-height.banner-text-center {
      min-height: 200px;
    }
  }

  .content-wrap section.bg-white .row {
    width: 100%;
  }

  section article {
    max-width: 1256px;
    margin: auto;
    width: 100%;
    padding: 0 15px;
  }

  .body_content h1 {
    line-height: 94px;
    letter-spacing: -2.8px;
  }

  section article h1 {
    margin: 13px auto 3px;
  }

  .fs-55-size {
    font-size: 55px;
  }

  .display-block {
    display: block;
  }

  .fs-40-size {
    font-size: 40px;
    line-height: 1.2;
  }

  .color-red {
    color: #e40000 !important;
  }

  .font-light {
    color: red;
  }

  #monthly-bundle-local .flex-col>div {
    height: 70px
  }

  .font-400 {
    font-weight: 400
  }

  .photo_caption {
    margin-left: 37px
  }

  #decemberNewsletter img,
  #novemberNewsletter img,
  #octoberNewsletter img {
    height: 300px;
    max-height: 300px
  }

  .home-banner h1 {
    letter-spacing: -.1px
  }

  .leftS .num {
    font-size: 120px;
    display: table-cell;
    vertical-align: middle;
    line-height: .3;
    font-weight: 100;
  }

  .leftS {
    width: 280px;
    display: inline-block;
    font-size: 16px
  }
  .leftS .txtnum {
    font-size: 18px;
    display: table-cell;
    vertical-align: middle;
    width: 300px;
    font-weight: 100;
    padding-top: 10px;
}

  .fs-18 {
    font-size: 18px
  }

  .fs-56 {
    font-size: 35px
  }

  .fs-34 {
    font-size: 34px
  }

  .fs-24 {
    font-size: 24px
  }

  .fs-23 {
    font-size: 23px
  }

  .fs-15 {
    font-size: 15px
  }

  #midContainer .midInCon .midBox1 .fbCon h2 {
    font-size: 20px;
    color: #ed1c24;
    float: left
  }

  #midContainer .midInCon ul li {
    margin: 0 0 5px;
    padding: 4px 10px 0
  }

  #midContainer .midInCon .midBox1 h3 {
    color: #ed1c24;
    font-size: 15px;
    font-weight: 700;
    padding: 15px 0 2px
  }

  #midContainer .midInCon .bCrumb {
    margin-bottom: 20px !important;
    border-bottom: 1px dotted #d8d8d8;
  }

  #midContainer .midInCon .midBox1 .table4 tr td {
    padding: 7px;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5
  }

  #midContainer .midInCon .midBox1 .fbCon h2 {
    text-transform: uppercase
  }

  #midContainer .midInCon .midBox1 .table4 tr td:first-child {
    border-left: none
  }

  #midContainer .midInCon .midBox1 .table4 {
    margin-top: 15px;
    margin-bottom: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    border-collapse: inherit;
    width: 90%
  }

  .callbackIcon {
    color: #e40000 !important
  }

  section.banner article {
    min-height: 300px;
    position: relative;

  }

  textarea.form-control {
    height: auto;
  }

  @media screen and (min-width: 768px) {
    section.banner article .banner-content {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      max-width: 100%;
      left: 0;
    }
    div#accordion-n {
      margin-right: -20px;
  }
  }

  @media(max-width: 460px) {
    .flex-icons-imgs.pull-right {
      width: 52%
    }

    .font-size-48 {
      font-size: 30px !important
    }

    .bggray_hover.call {
      padding-bottom: 130px !important
    }

    .imgIcon img {
      top: 0 !important
    }

    .icon-image-content img {
      top: 0 !important;
      padding-right: 0 !important
    }

    .padding-left-css-10,
    .padding-leftCss-10 {
      padding-top: 20px !important
    }

    .flex-block .flex-icons {
      top: 100px !important;
      font-size: 91px !important
    }

    .flex-block .flex-icons-imgs {
      top: 0 !important
    }

    .padding-0 {
      padding-top: 1px
    }

    .query-section.newsletter-contents {
      padding-left: 0 !important
    }

    .mob-banner-content .color-red.text-center {
      line-height: 1
    }

    #div_select_databundles {
      padding-top: 15px;
      padding-left: 30px
    }

    .content-wrap section {
      padding-top: 60px;
      padding-bottom: 60px;
      position: relative;
    }

    .padding-top-0,
    .pt-0 {
      padding-top: 0 !important;
    }

    .pd-left-15 {
      padding-left: 0
    }
  }

  .center-content {
    position: relative
  }

  .fs-55 {
    font-size: 55px
  }

  .fs-40 {
    font-size: 40px
  }

  .web-banner-content {
    position: absolute;
    top: 90px;
    left: 0
  }

  .home-banner-img {
    float: right
  }

  .text-center {
    text-align: center;
    display: inline-block
  }

  #midContainer .midInCon .midBox1 .fbCon h2 {
    font-size: 18px !important;

    font-weight: 400
  }

  .font-light {
    line-height: 1 !important
  }

  .pageHeading .font-light {
    font-size: 35px !important;
    line-height: 1 !important;
    border-bottom: 1px dotted #d8d8d8;
    letter-spacing: -.4px;
    padding-bottom: 12px !important
  }

  .pageHeading {
    margin-bottom: 24px;

    h5 {
      margin: 0;
    }
  }

  .list-view.fs-19 {
    padding: 0;
    margin: 15px 0 0
  }

  .fs-19 li {
    list-style: none;
    padding: 0;
    margin: 0
  }

  .fs-19,
  .fs-19 li {
    font-size: 19px
  }

  .query-section {
    padding: 0 0 0 35px !important;
    background: #fff
  }

  .accordion-content ul li {
    list-style: none !important
  }

  .accordion-toggle.fs-21 {
    line-height: 1
  }

  .padding-left-22 {
    padding-left: 8px
  }

  .padding-left-0 {
    padding-left: 0
  }

  .fs-25 {
    font-size: 21px
  }

  .table-responsive {
    border: none !important;
    margin-bottom: 0 !important;
    width: 100% !important;
  }

  .accordion-toggle {
    cursor: pointer;
    margin: 10px 0
  }

  .accordion-content {
    display: none
  }

  .accordion-content.default {
    display: block
  }

  .link .icon-outlined-link-right-arrow {
    color: transparent;


  }

  .link:hover .icon-outlined-link-right-arrow {
    color: #f00;
  }

  .airtel-num-margin-5 {
    margin: 5px 0
  }

  .accordion-toggle-faq {
    margin-top: 5px
  }

  .link {
    white-space: inherit !important
  }

  .pageHeadingContent p {
    margin-bottom: 15px;

  }

  .pageHeadingContent .inner-plan {
    margin-bottom: 0 !important
  }

  .pageHeadingContent {
    margin-bottom: 20px
  }

  .mob-banner-content {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center
  }

  .margin-left-6 {
    margin-left: -9px
  }

  .numbering {
    margin-left: -24px !important
  }

  .numbering.common-num {
    margin-left: -37px !important
  }

  .bold-step-text {
    font-weight: 400
  }

  .sub-heading {
    padding-bottom: 0 !important
  }

  .content-top-heading tr th {
    font-size: 20px !important;
    font-weight: 400;
    width: 18% !important;
    text-align: center
  }

  .content-top-heading tr {
    border-bottom: 1px solid #d4d4d4;
    height: 50px;
    text-align: center
  }

  .idSec_games_club {
    padding-top: 0 !important
  }

  .top-0 {
    padding-bottom: 10px !important
  }

  .accordion-left-panel-setting {
    padding-left: 23px
  }

  
  .select-style {
    padding: 0;
    margin: 0;
    min-width: 340px;
    border-bottom: 1px solid #000;
    overflow: hidden;
    background: url('https://cdn-webportal.airtelstream.net/website/zambia/assets/images/drop-down-arrow.jpg');
    background-repeat: no-repeat;
    background-position: 100%;
    width: 50%;
    height: 48px;
  }

  #div_select_databundles .select-style {
    width: auto;
    min-width: 300px;
    border-bottom: 1px solid #000;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: right;
  }
  div#div_select_databundles {
    width: 100%;
    float: left;
    margin: 20px 0 0;

    .leftS .txtnum {
      font-weight: 100;
      font-size: 18px;
    }
}

  .pd-left-15 {
    padding-left: 15px
  }

  .consumer-benefit li {
    margin: 0 !important
  }

  #div_collaboration h3.font-light,
  #div_id_connectivity h3.font-light,
  #div_id_voice h3.font-light {
    font-size: 35px !important;
    color: red !important
  }

  #div_collaboration table tr>td {
    border-bottom: none
  }

  #business_need {
    font-size: 35px !important;
    color: red !important
  }

  #div_id_voice ul {
    list-style: none
  }

  .padding-top-15,
  .pt-15 {
    padding-top: 15px !important;
  }

  #CentralRegion ul li,
  #div_closedUserGroup ul li,
  #div_id_voice ul li,
  #div_site_interconnection ul li {
    margin: 0 !important;
    padding: 0 !important
  }

  tr#network_coverage td {
    border-left: none !important
  }

  #careers h3.sub-heading {
    font-weight: unset !important;
    color: #000 !important
  }

  #senior-level-heading h3 {
    color: red !important
  }

  #use-airtel-money li {
    margin: 0 !important;
    padding: 0 !important
  }

  #got-better-table {
    border: none !important
  }

  #got-better-table tr td {
    border-left: none !important
  }

  .visible-xs {
    display: none !important;
}
.heading-text-bold.margin-bottom-0 {
  margin-top: 12px;
  font-weight: 700;
}

  @media (min-width: 767px) and (max-width:992px) {

    #about-airtel-money div.home-banner-img a img,
    #airtel-shops div.home-banner-img a img,
    #dealers div.home-banner-img img,
    #network-coverage div.home-banner-img a img {
      max-height: 228px
    }

    #about-airtel-money .fs-55,
    #airtel-shops .fs-55,
    #dealers .fs-55,
    #network-coverage .fs-55 {
      font-size: 38px !important
    }

    .font-size-48 {
      font-size: 36px !important
    }

    .padding-right-80,
    .pr-80 {
      padding-right: 0 !important
    }

    .flex-block .flex-icons,
    .flex-block .flex-icons-imgs {
      font-size: 115px !important
    }
  }

  #airtel-shops table.table4,
  #idSec_extra_masappe table.table4 {
    border: none !important
  }

  #airtel-shops table.table4 td,
  #idSec_extra_masappe table.table4 td {
    border-left: none !important
  }

  .hidden-bullets-point ul li {
    list-style: none
  }

  .kyc_location_table tr {
    border-bottom: 1px solid #eee
  }

  .kyc_location_table tr th {
    font-size: 21px;
    font-weight: 400
  }

  .kyc_location_table tr td {
    font-size: 19px
  }

  .pageHeadingContent.text-space {
    line-height: 30px
  }

  .contact-list-style-none ul li {
    list-style: none
  }

  .contact-list-style-none ul.email-text li {
    padding-left: 0 !important
  }

  .whats_new .what-margin {
    margin-bottom: 10px
  }

  .ques-left-15 {
    padding-left: 15px
  }

  .strong-txt_wts_new {
    font-weight: 400
  }

  .banner-bottoms {
    padding-bottom: 60px !important
  }

  .image-container {
    width: 80%;
    margin: 0 auto
  }

  .image-container img {
    width: 100%
  }

  .sponsor-content {
    padding-bottom: 0
  }

  .sponsor-content .sponser {
    padding-bottom: 15px
  }

  .sponHeading {
    padding: 20px 0;
    font-size: 22px
  }

  .left-44-content {
    padding-left: 15px
  }

  .pageHeadingContent ul li {
    list-style: none
  }

  #accordion .panel-title {
    line-height: 0 !important
  }

  #accordian_root .panel-title .accordion-toggle {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 21px;
    line-height: 1;
    display: inline-block
  }

  #accordian_root .panel-default>.panel-heading a {
    color: #000 !important
  }

  #accordian_root .panel-default>.panel-heading .panel-title>a:hover {
    color: red !important
  }

  #accordian_root .panel-default .panel-body {
    font-size: 19px;
    color: #4d4d4d
  }

  #accordian_root .panel-group .panel {
    margin-top: 10px !important
  }

  #accordian_root ul li {
    list-style: none
  }

  #accordian_root strong {
    font-weight: 400;
    font-size: 18px
  }

  #accordian_root .panel-body table strong {
    font-size: 21px !important
  }

  #accordian_root table tr {
    border-bottom: 1px solid #eee !important
  }

  #accordian_root table tr td {
    font-size: 22px;
    padding: 8px 0
  }

  #accordian_root table {
    margin-bottom: 30px
  }

  .business_list_view li a {
    font-size: 16px !important;
    color: #000
  }

  .business_list_view {
    padding-top: 30px;
    padding-left: 20px
  }

  .color-yellow {
    color: #fdc400
  }

  .font-size-48 {
    font-size: 48px
  }

  .business_list_view ul li>a:hover {
    color: none !important
  }

  .color-white {
    color: #fff
  }

  .padding-left-css-10 {
    padding-left: 10px
  }

  .flex-block .flex-icons,
  .flex-block .flex-icons-imgs {
    position: absolute;
    top: 150px;
    right: 0;
    max-height: 100%;
    max-width: 67%;
    font-size: 150px
  }

  .flex-container {
    margin: 0
  }

  .imgIcon img {
    top: 100px !important
  }
  .small, small {
    font-size: 12px!important;
    line-height: 1.58;
    letter-spacing: .8px;
}
  .mobile-STDcode {
    position: relative;

    .stdCode {
      font-weight: 100;
      top: 8px;
    }

    #mNumber {
      padding-left: 60px;
    }

  }

  #email {
    border: 0;
    border-bottom: 1px solid #d4d4d4;
    height: 33px !important;
    padding: 0;
    box-shadow: none !important;
  }

  .container-col-6 {
    position: relative;
    width: 570px;
    max-width: 99%;
  }

  .flex-height.flex-block div {
    min-height: 100%;
  }

  .business_list_view {
    padding-top: 30px;
    padding-left: 20px;
  }

  .business_list_view li a {
    font-size: 16px;
    color: #000;
  }

  .flex-block .flex-icons,
  .flex-block .flex-icons-imgs {
    top: 125px;
    font-size: 130px;
  }

  .color-white,
  a.color-white:hover {
    color: #fff !important;
  }

  section.padding-top-0.bg-white.roaming-bundle-top-bottom {
    float: left;
    width: 100%;
  }

  section.banner.padding-top-0.padding-bottom-0.airtel-app.bg-white.home-banner.overflow-hidden.airtelwow.animated {
    float: left;
    width: 100%;
  }
  section#idSec_extra_masappe {
    float: left;
    width: 100%;
}
article#dealers {
  margin-top: 29px;
}
  @media (max-width: 767px) {
   article#dealers > .row {
      flex-wrap: wrap;
      
  }
      .visible-xs {
        display: block !important;
    }
    .hidden-xs {
      display: none !important;
    }
    .prepaid-rate-150 {
      width: 150px !important
    }

    .padding-left-css-10,
    .padding-leftCss-10 {
      padding-left: 35px !important
    }

    .font-size-48 {
      font-size: 36px
    }

    .pr-80.flex-icons,
    .pr-80.flex-icons-imgs {
      padding-right: 20px !important
    }

    .flex-block .flex-icons {
      top: 50px
    }

    .flex-block .flex-icons,
    .flex-block .flex-icons-imgs {
      font-size: 115px
    }
  }






  @media (max-width: 320px) {
    .font-size-48 {
      font-size: 30px
    }

    .business_list_view li a {
      font-size: 14px !important
    }

    .flex-block .flex-icons,
    .flex-block .flex-icons-imgs {
      font-size: 90px
    }

    .padding-right-40,
    .pr-40,
    .pr-80.flex-icons,
    .pr-80.flex-icons-imgs {
      padding-right: 0 !important
    }

    .padding-left-css-10,
    .padding-leftCss-10 {
      padding-left: 15px !important
    }
  }

  .listStyle {
    list-style: none
  }

  .fs-21-height .fs-21 {
    line-height: 40px !important
  }

  .question-height {
    line-height: 34px
  }

  .premier_lifestyle_benifit {
    margin-bottom: 20px
  }

  .select-style select {
    padding: 5px 8px;
    padding-right: 25px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 200;
  }

  .select-style select:focus {
    outline: none
  }

  .accordion-content,
  .accordion-content2 {
    font-size: 19px;
    line-height: 28px
  }

  .accordion-content {
    color: #555 !important
  }

  .heading_font_size p {
    font-size: 21px;
    line-height: 29px
  }

  .table-font tr td {
    font-size: 21px
  }

  #firstblock .row {
    align-items: center;
  }

  #secondblock1 {
    margin-top: 20px
  }

  #secondblock {
    display: flex;
    padding-left: 0 !important;
    margin-top: 35px;
  }

  .col-xs-12 {
    width: 100%;
  }

  .font-light.font-heading-35 {
    font-size: 35px !important;
    line-height: 50px;
    margin-top: 0 !important;
    padding-top: 15px !important
  }

  .border-bottom tr {
    border-bottom: 1px solid #eee
  }

  .block-padding {
    padding-bottom: 15px !important
  }

  .lh-34 {
    line-height: 34px
  }

  .ls-none ul li {
    list-style: none
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 24px;
  }

  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>td,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>thead>tr>th {
    white-space: normal
  }

  .banner-text-center {
    text-align: left
  }

  .display-block {
    display: block
  }

  .line-height {
    line-height: 1
  }

  .image-section {
    max-width: 380px;
    margin: 0 auto
  }

  .pull-right {
    float: right !important;
  }

  .fs-55-size {
    font-size: 55px
  }

  .fs-40-size {
    font-size: 40px;
    line-height: 1.2
  }

  .image-section>img {
    width: 100%
  }

  ul {
    padding-left: 10px
  }

  .table {
    margin-bottom: 10px !important
  }

  .font-light {
    font-weight: 100 !important;
  }

  .fs-21 {
    font-size: 21px !important;
    line-height: 1.3 !important;
  }

  .flex-col>div:first-child span,
  .table>thead>tr>th {
    font-size: 21px !important;
    font-weight: 400 !important
  }

  .flex-col>div{
    font-size: 19px !important
  }
  
  .ch-block {
    display: block
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  table tr {
    height: 35px;
    border-bottom: 1px solid #eee;
  }

  .table>caption+thead>tr:first-child>td,
  .table>caption+thead>tr:first-child>th,
  .table>colgroup+thead>tr:first-child>td,
  .table>colgroup+thead>tr:first-child>th,
  .table>thead:first-child>tr:first-child>td,
  .table>thead:first-child>tr:first-child>th {
    border-top: 0;
  }

  .flex-col>div:first-child span,
  .table>thead>tr>th {
    font-size: 21px !important;
    font-weight: 400 !important;
  }

  .table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    padding: 8px;
    line-height: 1.5;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }

  .flex-col>div{
    font-size: 19px !important;
  }
  .table>tbody>tr>td {
    font-size: 16px !important;
  }
  .padding-bottom-50, .pb-50 {
    padding-bottom: 50px !important;
}
  .table>tbody>tr>td {
    border: none;
  }

  .form-control {
    width: 100%;
    height: 50px;
    padding: 12px 24px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  select#service_channel {
    color: #989898;
    font-size: 16px;
    appearance: none;
}
  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=text],
  select,
  textarea {
    font-size: 16px;
    color: #000;
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #d4d4d4;
    border-radius: 0;
    padding: 0;
    position: relative;
    vertical-align: middle;
    z-index: 1;
    height: 36px;
    outline: none;
    box-shadow: none;
  }

  input[type=number],
  input[type=password],
  input[type=tel],
  input[type=text],
  select,
  textarea,
  .form-control {
    height: 51px;
    font-size: 16px;
    font-weight: 400;
  }

  .input-border {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid rgba(126, 117, 117, 0.29);
    box-shadow: none !important;
  }

  fieldset {
    width: auto;
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    border: none;
  }

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
    transition: .5s;
    letter-spacing: 2px;
    vertical-align: middle;
    border: none;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 17px 40px;
    font-size: 20px;
    line-height: 1.3333333;
    color: #fff;
    background-color: #e40000;
    border-color: #df0000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn-default {
    color: #fff;
    border-color: #ccc;
    background: #e82523;
  }

  .btn {
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 700;
  }

  .btn-primary {
    color: #fff;
    background-color: #e40000;
    border-color: #df0000;
  }

  .box-3 {
    display: flex;
    flex-wrap: nowrap;
  }

  .box-2 {
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
    text-align: center;
    margin-top: 10px;
    padding: 19px;
    margin: 10px;
  }

  #midContainer .midBox1 span {
    // color: #dd3532;
    font-weight: 400;
  }
  .form-horizontal .control-label {
    text-transform: uppercase;
  }

  @media (max-width: 676px) {
    .box-3 {
      display: block;
    }
  }

  @media (max-width: 480px) {
    .fs-55-size {
      font-size: 30px !important
    }

    .fs-40-size {
      font-size: 25px !important
    }
  }

  @media (max-width: 767px) {
    .fs-55-size {
      font-size: 40px
    }

    .fs-40-size {
      font-size: 35px
    }

    .banner-text-center {
      text-align: center
    }
  }

  @media (min-width: 768px) and (max-width:860px) {
    .fs-55-size {
      font-size: 40px
    }

    .fs-40-size {
      font-size: 35px
    }
  }



  /* About Money */

  .flex-container {
    display: flex;
    width: 100%;
  }

  section.m-flex-use {
    padding-bottom: 0 !important;
    padding-top: 30px !important;
  }

  .m-airtel-money .container-col-6 {
    width: 575px;
    max-height: 100%;
    min-height: 280px;
    overflow: hidden;
    right: auto;
  }

  .gallery-item h2,
  .text h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 15px 0 8px;
    color: #4a4a4a;
  }
  .nav-tabs {
    box-shadow: inset 0 -1px 0 #d4d4d4;
    text-align: center;
    max-width: 100%;
    background: #fff;
    z-index: 9;
    transition: .5s;
    position: relative;
}
 #sub-tabbing h6 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: .2px;
}
#tabbing .switch-tab li.tab {
  background: #fff;
}
h4.tab_heading {
  font-size: 24px;
  font-weight: 400;
}
  @media screen and (min-width: 768px) {
    .pull-right-sm {
      float: right;
    }
  }

  @media (min-width: 768px) {
    .pull-right-sm {
      float: right;
    }
  }

  .m-home-block-content .link {
    bottom: 18%;
    position: absolute;
    color: #fff;
  }

  .flex-block .flex-img,
  .flex-bottom {
    bottom: 0;
    position: absolute;
  }

  .flex-block .flex-img {
    transition: 1.3s;
    right: 0;
    max-height: 100%;
    max-width: 67%;
  }

  .flex-block:not(:first-child) .container-col-6 {
    padding-left: 50px;
  }



  .min-height-ar {
    min-height: auto !important;
  }

  .padding-left-25,
  .pl-25 {
    padding-left: 25px !important;
  }

  .padding-left-40,
  .pl-40 {
    padding-left: 40px !important;
  }

  .padding-bottom-30,
  .pb-30 {
    padding-bottom: 30px !important;
  }

  .padding-top-20,
  .pt-20 {
    padding-top: 20px !important;
  }

  .color-black {
    color: #000 !important;
  }

  .bg-red {
    background-color: #e40000;
    color: #fff;


  }

  .bggray_hover:nth-child(2) {
    background: #eee;

  }

  section.m-flex-use.clear.flex-block.bg-white.bggray_hover {
    background: #ffff !important;


  }

  a u {
    text-decoration: underline;
  }

  .color-purple {
    color: #7c4d9e !important;
  }

  .img-content-row h2 {
    font-size: 20px !important;
    line-height: normal !important;
  }

  @media (min-width: 768px) {
    .flex-block {
      width: 50%;
    }
  }

  .breadcrumbs {
    padding: 0 16px;
  }
  #our_stories .row {
    flex-wrap: wrap;
    margin: 0;
  }
  
  div#online-safety {
    padding: 0 20px;
}


  @media (max-width: 767px) {
    .m-fs-51 {
      font-size: 22px;
    }

    section.bg-white h1.line-height.banner-text-center {
      min-height: auto;
    }

    .col-sm-6.top-80,
    .col-sm-8.top-80 {
      width: 100%;
    }

    section {
      padding: 20px 0;

    }

    .top-80 {
      padding-top: 20px;
    }

    // .accordion-content, 
    // .accordion-content2,
    // strong,
    //   .flex-col > div, 
    //   .table > tbody > tr > td,
    //  .flex-col > div:first-child span, 
    //   .table > thead > tr > th,
    //   .fs-19,
    //   .fs-19 li,
    //   .fs-21 ,.font-size-20 {
    //       font-size: 14px !important;
    //   }

    .h5,
    h5 {
      font-size: 18px;
    }

    .flex-container {
      width: 100%;
      flex-wrap: wrap;
    }

    section.m-flex-use {
      width: 100%;
    }

    .m-airtel-money .container-col-6 {
      width: 100% !important;
      padding: 0 15px !important;
    }

    .m-airtel-money h4 {
      font-size: 24px;


    }

    #div_select_databundles {
      padding-top: 0;
      padding-left: 0;
    }
    .div-responsive {
      overflow: auto;
  }
  .leftS {
    height: auto !important;
    .txtnum {
      font-weight: 100;
    font-size: 18px;
    }
  }
  }



}