@import "../../../sass/custom.scss";
@import "../../../sass/scroller.scss";


.store-locator {
    .search-bar {
        max-width: 1190px;
        display: flex;
        align-items: center;
        margin: 40px auto;

        .search-bar-inner {
            width: 100%;
            margin-top: 60px;
        }
        .find {
            font-size: 36px;
            font-weight: 100;
            margin-bottom: 0;
        }

        .btn {
            font-size: 16px;
            padding: 8px;
            border-radius: 0;
            letter-spacing: 2px;
            font-weight: 100;
            color: #fff !important;
            transition: .5s;
            &:hover {
                background: #a30000;
                border-color: #a30000;
                color: #fff;
            }
        }

        .crt-loc-btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .map-list-wrapper {
        display: flex;
        flex-direction: row;
        max-height: 350px;
        overflow: hidden;

        .list-container {
            max-height: 350px;
            overflow: auto;
            overflow-x: hidden;
            @include scroll;
            padding-right: 15px;

            .card-info {
                display: flex;
                flex-direction: column;
                cursor: pointer;
                min-height: 120px;
                margin-left: 35px;
                margin-right:15px;
                border-top:1px solid #d4d4d4;
                padding: 10px 0;

                .card-body {
                    line-height: normal;
                    color: #2d2d2d;
                    font-weight: 100;
                }
                .card-head {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .left {
                        font-size: 16px;
                        color: #2d2d2d;
                        font-weight: 100;
                    }
                    .distance {
                        display: flex;
                       align-items: center;
                       h3 {
                        font-weight: 100;
                       }
                    }

                    .link-arrow:after {
                        font-family: airtel-icons;
                        content: "\F16E";
                        font-size: 130%;
                        vertical-align: sub;
                        margin-left: 15px;
                        display: inline-block;
                        top: -8px;
                        position: relative;
                    }

                    a,
                    a:hover {
                        color: red
                    }

                }

                .link:hover .icon-outlined-link-right-arrow,
                .tiles-block:hover .link-arrow:after,
                .link-arrow:hover:after {
                    animation: linkanimation 0.7s linear;
                }

                .link:hover .icon-outlined-link-right-arrow,
                .tiles-block:hover .link-arrow:after,
                .link-arrow:hover:after {
                    animation: linkanimation 0.7s linear;
                }

                .icon-outlined-link-arrow-right:before,
                .icon-outlined-link-right-arrow:before {
                    content: "\F16E";
                }

            }
        }

    }
    .font-size-14, .fs-14 {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 1.2px;
    }
    .circle-red {
        width: 10px;
        height: 10px;
        background: red;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 50px;
        display: inline-block;
        border: #fff 1px solid;
        margin-right: 5px;
    }
}
.or-text {
    font-size: 18px;
    padding-right: 15px;
}
.search-field {
    position: relative;
    width:86% !important;

    .form-control {
        font-size: 31px;
        color: #000;
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid #d4d4d4;
        border-radius: 0;
        padding: 0;
        position: relative;
        vertical-align: middle;
        z-index: 1;
        height: 36px;
        outline: none;
        box-shadow: none;
        height: auto!important;
        padding-right: 40px;
    }
    .autocomplete-search{
        position: absolute;
        right: 0;
        top: 12px;
        font-size: 18px;
        cursor: pointer;
        z-index: 1;
    }
}
.errorMessage {
    color: #f00;
}
.location-not-found {
    padding: 50px 0;
    .color-md-grey {
        color: #9f9f9f;
    }
    .margin-bottom-30, .mb-30 {
        margin-bottom: 30px;
    }
    img {
     height: 240px;
    }
     h2 {
        font-size: 80px;
        line-height: 80px;
        letter-spacing: -.8px;
        font-weight: 100;
    }
    .color-grey {
        color: #707070;
    }
    .font-size-20, .fs-20 {
        font-size: 20px;
        line-height: 24px;
    }
}

@media (max-width:992px) {
    .search-bar {
        padding: 0 20px;
    }
    .store-locator .search-bar .find {
        font-size: 22px;
      
        margin-bottom: 10px;
    }
    .store-locator .search-bar .crt-loc-btn {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
        align-items: center;
    }
    .store-locator .search-bar .btn {
        width: 100%;
    }
    .location-not-found h2 {
        font-size: 40px;
        line-height: 40px;
    }
    .location-not-found   p {
        font-size: 16px;
    }
}