.airtel-money {
    color: #e40000;
    display: flex;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
    i {
        margin-right: 10px;
      
    }
}
.category-conatiner {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    background: #ebebeb;
}
.category-wrapper {
   
    .content-title {
        font-size: 48px;
        line-height: 50px;
        color: #707070;
        font-weight: 100;
        letter-spacing: -.4px;
    }
    .category {
        height: 115px;
        color: #000;
        border: 1px solid #d4d4d4;
        padding: 30px 10px 0 40px;
        cursor: pointer;
        font-size: 16px;
        line-height: 26px;
        font-weight: 100;

        span {
            position: absolute;
            bottom: 10px;
            right: 40px;
            font-size: 30px;
            color: #555;
            top: auto;
        }
    }
  
}
.card-questions-container {
    padding: 20px 0;
   

        h3 {
            color: #707070;
        
            padding-right: 30px;
            font-size: 48px;
            line-height: 50px;
            letter-spacing: -.4px;
            font-weight: 100;
        }
        .questions {
            display: block;
            padding-bottom: 30px;
            color: #000;
            font-size: 20px;
            line-height: 24px;
            font-weight: 100;
            cursor: pointer;
        }
    
}