@import "../sass/variables/colors";


@mixin scroll {
  &::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
   
  }
  &::-webkit-scrollbar-thumb {
    background: $airtel-brand;
    border-radius: 10px;
    cursor: pointer;
  }
 
}
@mixin scroll-x {
  &::-webkit-scrollbar {
    height: 3px;
    cursor: pointer;
   
  }
  &::-webkit-scrollbar-thumb {
    background: $airtel-brand;
    border-radius: 10px;
    cursor: pointer;
  }
 
}
