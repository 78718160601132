.color-md-grey {
    color: #9F9F9F !important; 
}
.btn {
    
    text-align: center;
    text-transform: uppercase;
    
    vertical-align: middle;
    border: none;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    font-size: 16px;
    line-height: 1.3333333;
    color: #fff;
    background-color: #E40000;
    border:none;
     }
     .font-size-14 {
        font-size: 14px!important;
    }
    
    .icon-outlined-link-right-arrow{
      font-size: 130%;
      vertical-align: text-bottom;
      margin-left: 8px;
      top: 3px;
      position: relative;
      display: inline-block;
    }
    
    @keyframes linkanimation {
      0% {
          transform: translateX(0)
      }
    
      50% {
          transform: translateX(20px)
      }
    
      to {
          transform: translateX(0)
      }
    }
    
    .link-arrow:hover:after, 
     .link:hover .icon-outlined-link-right-arrow,
     .tiles-block:hover .link-arrow:after {
      animation: linkanimation .7s linear;
    }
    