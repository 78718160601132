.top-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
    background: #f6f6f6;

    .icon-size-40 {
        font-size: 40px;
        cursor: pointer;
    }

    p {
        font-size: 20px;
        color: #707070;
        line-height: 24px;
    }

    h3 {
        font-size: 48px;
        line-height: 50px;
        letter-spacing: -.4px;
        font-weight: 100;
    }
}

.content-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
}
.feedback-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    hr {
        margin: 30px 0;
        opacity: 1;
        background: #000;
        height: 1px;
        display: block;
        width: 100%;
        width: 100%;
        max-width: 500px;
        border: none;
    }
}

.helpful-wrap {
    .row {
        width: 100%;
        max-width: 500px;
    }

    h3 {
        font-size: 29px;
        font-weight: 100;
        margin: 0 0 4px;
    }

    p {
        color: #707070;
    }

    .feedback_icons {
        display: flex;
        justify-content: flex-end;

        .icon-medium {
            font-size: 53px;
            cursor: pointer;
            margin-right: 16px;

            &:hover {
                color: #f00;
            }
        }
    }


}

.thanks-you {
    a.icon-outlined-circle-checkmark.icon-medium.color-green {
        color: green;
        font-size: 49px;
    }

    .color-green {
        color: green;
    }

    p {
        font-size: 22px;
    }
}